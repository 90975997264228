.pdf {
    position: absolute;
    inset: 20px;
    height: auto !important;
}

.btn-rotate {
    display: flex;
    z-index: 10;
    position: absolute;
    top: 4px;
    right: 190px;
}

  .pdf-false-dark{
    .btn-dark {
        background: transparent;
        color: #fff;
    
        button{
            color: #fff;
        }
      }
      
      .btn-light {
        background: transparent;
        color: #000;
      }
      
      .btn-dark:hover {
        background-color: #191919;
        border-radius: 5px;
      }
      
      .btn-light:hover {
        background-color: #d6d6d6;
        border-radius: 5px;
      }
  }
  .pdf-dark{
    
      .btn-dark {
          background: transparent;
          color: #fff;
      
          button{
              color: #fff;
          }
        }
        
        .btn-light {
          background: transparent;
          color: #000;
        }
        
        .btn-dark:hover {
          background-color: #191919;
          border-radius: 5px;
        }
        
        .btn-light:hover {
          background-color: #d6d6d6;
          border-radius: 5px;
        }

        .rpv-core__icon{
          color: #ffffff;
        } 
  }
  
  .pdf-modal{
    .btn-dark {
        background: transparent;
        color: #fff;
    
        button{
            color: #fff;
        }
      }
      
      .btn-light {
        background: transparent;
        color: #000;
      }
      
      .btn-dark:hover {
        background-color: #191919;
        border-radius: 5px;
      }
      
      .btn-light:hover {
        background-color: #d6d6d6;
        border-radius: 5px;
      }
  }

  .pdf{
    
      .btn-dark {
          background: transparent;
          color: #e1e0e0;
      
          button{
              color: #fff;
          }
        }
        
        .btn-light {
          background: transparent;
          color: #363636;
        }
        
        .btn-dark:hover {
          background-color: #191919;
          border-radius: 5px;
        }
        
        .btn-light:hover {
          background-color: #d6d6d6;
          border-radius: 5px;
        }

  }

  .rpv-core__minimal-button--selected{
    background: transparent;
  }